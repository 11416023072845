import React from 'react';

function SocialLinks(props) {
  const { social } = props
  function socialLinks() {
    return social.map(function (network) {
      let cssClass = 'fa ' + network.className
      return <li key={network.name}><a href={network.url} target="_blank" rel="noopener noreferrer"><i className={cssClass}></i><span className="visually-hidden">{network.name}</span></a></li>
    })
  }
  return (
    <ul className="social">
      {socialLinks()}
    </ul>
  );
}

export default SocialLinks;
