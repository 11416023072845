import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import $ from 'jquery';
import '../../css/components/nav.css';
import { scroller } from 'react-scroll'

class Nav extends Component {
  constructor (props) {
    super(props);
    this.state = {
      cssClass: '',
      mobileCssClass: 'nav visually-hidden-mobile'
    };
    this.toggleMobileNav = this.toggleMobileNav.bind(this);
    this.navigate = this.navigate.bind(this);
  }

  updateScroll = () => {
    const h = $('header').height();
    const y = $(window).scrollTop();
    const isLargeScreen = $(window).outerWidth() > 768;
    const isInHeaderTop = y > h * .05 && y < h;
    const isInHeaderBelowTop = y < h * .20;

    this.setState(() => {
      return isInHeaderTop && isLargeScreen === true ? { cssClass: 'fadeOut' }
        : isInHeaderBelowTop === true ? { cssClass: 'fadeIn' }
          : { cssClass: 'fadeIn opaque' }
    })
  };

  componentDidMount() {
    window.addEventListener('scroll', this.updateScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateScroll);
  }

  setActive(str) {
    document.title = "method7-" + this.props.waypointActive;
    return this.props.waypointActive === str ? 'current' : ''
  }

  toggleMobileNav(e) {
    this.setState(() => {
      return this.state.mobileCssClass === 'nav visually-hidden-mobile' ? { mobileCssClass: 'nav' }
        : { mobileCssClass: 'nav visually-hidden-mobile' }
    })
  }

  toggleButton() {
    const title = this.state.mobileCssClass === 'nav' ? 'Show navigation' : 'Hide navigation'
    return <a className="mobile-btn" onClick={this.toggleMobileNav} href="#nav-wrap" title={title}>{title}</a>
  }

  navigate(target) {
    scroller.scrollTo(target, {
      duration: 1500,
      smooth: "easeInOutQuint"
    })
    this.toggleMobileNav();
  }

  render() {
    return (
      <nav id="nav-wrap" className={this.state.cssClass} >
        {this.toggleButton()}
        <ul id="nav" className={this.state.mobileCssClass}>
          <li className={this.setActive('home')}>
            <NavLink exact={true} to="#home" onClick={() => this.navigate('home')}>Home</NavLink>
          </li>
          <li className={this.setActive('about')}>
            <NavLink exact={true} to="#about" onClick={() => this.navigate('about')}>About</NavLink>
          </li>
          <li className={this.setActive('technologies')}>
            <NavLink exact={true} to="#technologies" onClick={() => this.navigate('technologies')}>Technologies</NavLink>
          </li>
          <li className={this.setActive('resume')}>
            <NavLink exact={true} to="#resume" onClick={() => this.navigate('resume')}>Resume</NavLink>
          </li>
          <li className={this.setActive('portfolio')}>
            <NavLink exact={true} to="#portfolio" onClick={() => this.navigate('portfolio')}>Portfolio</NavLink>
          </li>
          <li className={this.setActive('testimonials')}>
            <NavLink exact={true} to="#testimonials" onClick={() => this.navigate('testimonials')}>Testimonials</NavLink>
          </li>
        </ul>
      </nav>
    );
  }
}
Nav.propTypes = {
  waypointActive: PropTypes.string.isRequired
};
export default Nav;
