import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import './App.css';
import Cv from './Components/Cv/Cv';
import ReactGA from 'react-ga';
import data from './json/engineData.json'

class App extends Component {

  constructor (props) {
    super(props);
    this.state = {
      waypointActive: 'home',
      resumeData: {}
    };
    this.setWaypoint = this.setWaypoint.bind(this);
  }

  componentDidMount() {
    ReactGA.initialize('UA-153512324-1', {
      testMode: process.env.NODE_ENV === 'test',
    });
    this.firePageView();
    this.getResumeData();
  }

  firePageView() {
    ReactGA.pageview('home');
  }

  getResumeData() {
    this.setState({ resumeData: data });
    // $.ajax({
    //   url: '/engineData.json',
    //   dataType: 'json',
    //   cache: false,
    //   success: function (data) {
    //     this.setState({ resumeData: data });
    //   }.bind(this),
    //   error: function (xhr, status, err) {
    //     console.log('/engineData.json ' + err);
    //   }
    // });
  }

  setWaypoint(waypoint) {
    this.setState({ waypointActive: waypoint })
  }

  render() {
    let content = Object.keys(this.state.resumeData).length > 0 ? <Cv data={this.state.resumeData} /> : <p>Loading Data!</p>
    return (
      <Router>
        <div className="App">
          {content}
        </div>
      </Router>
    );
  }
}

export default App;
