import React, { Component, lazy, Suspense } from 'react';
import { browserHistory } from "react-router";
import { scroller } from 'react-scroll'
import '../../App.css';
import Nav from './Nav';
import Header from './Header';
const About = lazy(() => import('./About'));
const Resume = lazy(() => import('./Resume'));
const Technologies = lazy(() => import('./Technologies'));
const Portfolio = lazy(() => import('./Portfolio'));
const Testimonials = lazy(() => import('./Testimonials'));
const Footer = lazy(() => import('./Footer'));

class Cv extends Component {

  constructor (props) {
    super(props);
    this.state = {
      waypointActive: 'home',
      resumeData: {}
    };
    this.setWaypoint = this.setWaypoint.bind(this);
    this.timer = null;
  }

  componentDidMount() {
    this.timer = setTimeout(this.scrollToHash, 200);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  scrollToHash = () => {
    const hash = browserHistory.getCurrentLocation().hash.replace('#', '');
    if (hash) {
      scroller.scrollTo(hash, {
        duration: 1500,
        smooth: "easeInOutQuint"
      })
    }
  }

  setWaypoint(waypoint) {
    this.setState({ waypointActive: waypoint })
  }

  render() {
    const { main, technologies, resume, testimonials, portfolio } = this.props.data;
    return (
      <React.Fragment>
        <Nav waypointActive={this.state.waypointActive} setWaypoint={this.setWaypoint} />
        <Header waypointActive={this.state.waypointActive} setWaypoint={this.setWaypoint} {...main} />
        <Suspense fallback={<h2>Content is loading...</h2>}>
          <About setWaypoint={this.setWaypoint} {...main} {...main.address} />
          <Technologies setWaypoint={this.setWaypoint} {...technologies} />
          <Resume setWaypoint={this.setWaypoint} {...resume} />
          <Portfolio setWaypoint={this.setWaypoint} {...portfolio} />
          <Testimonials setWaypoint={this.setWaypoint} {...testimonials} />
          <Footer {...main} />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default Cv;
