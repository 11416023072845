import React, { Component } from 'react';
import { Waypoint } from 'react-waypoint';
import PropTypes from "prop-types";
import SocialLinks from '../shared/SocialLinks'
import '../../css/components/header.css';
import { Link } from 'react-scroll'
import FitTxt from 'react-fittext'

class Header extends Component {
   constructor (props) {
      super(props);
      this.state = {
         width: 320,
         height: 586
      };
   }

   updateParent(str) {
      this.props.setWaypoint(str)
   }

   setActive(str) {
      document.title = "method7.co.uk/" + this.props.waypointActive;
      return this.props.waypointActive === str ? 'current' : ''
   }

   updateDimensions = () => {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
   };

   componentDidMount() {
      this.updateDimensions()
      window.addEventListener('resize', this.updateDimensions);
   }

   componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
   }

   cssStyle() {
      return {
         height: this.state.height
      }
   }

   render() {
      const { name, occupation, description, social } = this.props;
      return (
         <Waypoint topOffset="30%" bottomOffset="80%" onEnter={() => this.updateParent('home')}>
            <header id="home" style={this.cssStyle()}>
               <div className="row banner">
                  <div className="banner-text">
                     <FitTxt>
                        <h1 className="responsive-headline">{name}</h1>
                     </FitTxt>
                     <p><span>{occupation}</span> <span>{description}</span>.</p>
                     <hr />
                     <SocialLinks social={social} />
                  </div>
               </div>
               <p className="scrollEnds">
                  <Link to="about" smooth={true} duration={500}><i className="icon-down-circle"></i></Link>
               </p>
            </header >
         </Waypoint>
      );
   }
}
Header.propTypes = {
   setWaypoint: PropTypes.func.isRequired,
   data: PropTypes.object
};
export default Header;
